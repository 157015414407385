/* Spinner.css */
.overlay-loader {
  border: 6px solid #e31c58;
  border-radius: 50%;
  border-top: 6px solid transparent;
  width: 100px;
  height: 100px;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
