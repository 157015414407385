@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

.gradient {
  background: linear-gradient(to right top,#ffc600 20%,#ff0040,#e600cc 80%);
}
